<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader_vertical myHeader_home" ref="myHeader_home">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerLogoCampanaNotif
              :rut="formatNumber2(rutReact)"
              :nombre="nombre_simple_react"
            />
          </div>
        </div>

        <div class="home_banner centrado">
          <div class="cont_300_basico ancho_acotado">
            <div class="home_banner_text">{{ fecha_larga }}</div>
            <div class="home_banner_dolar_fecha">
              <div class="tarjetaBienvenidaDolar">
                {{ dolar_react }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="home_contenido_sin_blur_sup centrado"
        ref="home_conten"
        :style="contenido_home"
        v-show="cont_visible"
      >
        <div class="ancho_acotado home_body">
          <div class="contenido_home">
            <div class="cont_300N homeMisTransTitle title">
              {{ t("app.home.mis_transacciones") }}
            </div>

            <div class="homeMisTransCont">
              <div class="cont_300N">
                <div class="home_misTransacBody">
                  <div v-if="ultimasTransacciones.length > 0">
                    <div
                      v-for="(transaccion, i) in ultimasTransacciones"
                      :key="i"
                    >
                      <cardTransaction
                        :transaction="transaccion"
                        :index="i"
                        @transactionSelected="transactionSelectedLocal"
                        :transactionSelectedIndex="transactionSelectedIndex"
                        @mostrarDetalleTransac="mostrarDetalleTransacLocal"
                        @repetirTransac="repetirTransacLocal"
                        @repetirTransac_bloqueado="repetirTransac_bloqueado"
                      />
                    </div>
                  </div>

                  <div v-else class="home_sin_transac">
                    <div class="home_sin_transac_Img">
                      <img :src="sin_transacciones" alt="" srcset="" />
                    </div>
                    <div class="home_sin_transac_Tit">
                      {{ t("app.home.sin_transac_text1") }}
                    </div>
                    <div class="home_sin_transac_Text">
                      {{ t("app.home.sin_transac_text2") }}
                    </div>

                    <div v-if="!sin_destinatarios">
                      <div class="home_sin_transac_Text_2" @click="callEnviar">
                        {{ t("app.comunes.btns.enviar") }}
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="home_sin_transac_Text_2"
                        @click="call_nuevo_destinatario"
                       
                      >
                        {{ t("app.comunes.btns.primer_destinatario") }}
                      </div>
                    </div>

                    <!-- <div
                      class="home_sin_transac_Text_3"
                      @click="call_nuevo_destinatario"
                      v-if="sin_destinatarios"
                    >
                      {{ t("app.comunes.btns.primer_destinatario") }}
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer footer_home centrado" ref="footer_home">
        <div class="footer_home_btns ancho_acotado">
          <div class="homeFooterTabs">
            <div class="" @click="callEnviar">
              <div class="homeFooterTabsIcon">
                <img :src="iconSend" alt="" srcset="" />
              </div>
              <div class="homeFooterTabsSpan">
                <span>{{ t("app.home.tabEnviar") }}</span>
              </div>
            </div>

            <div class="" @click="callNewDestinatario">
              <div class="homeFooterTabsIcon">
                <img :src="iconNuevo" alt="" srcset="" />
              </div>
              <div class="homeFooterTabsSpan">
                <span>{{ t("app.home.tabDestinatarios") }}</span>
              </div>
            </div>

            <div class="" @click="callButtonMenuSp">
              <div>
                <div class="homeFooterTabsIcon">
                  <img :src="iconCuenta" alt="" srcset="" />
                </div>
                <div class="homeFooterTabsSpan">
                  <span>{{ t("app.home.tabCuenta") }}</span>
                </div>
              </div>
            </div>

            <div class="" @click="callInfoLocal">
              <div class="homeFooterTabsIcon">
                <img :src="iconInfo" alt="" srcset="" />
              </div>
              <div class="homeFooterTabsSpan">
                <span>{{ t("app.home.tabInfo") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import headerLogoCampanaNotif from "../components/generales/headerLogoCampanaNotif.vue";
import { menuController, modalController, alertController } from "@ionic/vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { useI18n } from "vue-i18n";
import cardTransaction from "../components/generales/cardTransaction.vue";
import reciboTransferencia from "./modales/reciboTransferencia.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import { contenido_style } from "../assets/funciones/blur";

export default {
  setup() {
    const { t, locale } = useI18n();
    const slideOpts = {
      initialSlide: 0,
      speed: 3000,
    };
    return { t, slideOpts, locale };
  },
  name: "home",
  components: {
    headerLogoCampanaNotif,
    screen_width_cont,
    cardTransaction,
  },
  data() {
    return {
      iconSend: require("../assets/imgs/iconTabSend.svg"),
      iconNuevo: require("../assets/imgs/iconTabNuevo.svg"),
      iconCuenta: require("../assets/imgs/iconTabCuenta.svg"),
      iconInfo: require("../assets/imgs/iconTabInfo.svg"),
      fechaHoy: new Date().toLocaleDateString(),
      // dolar: 895, //simular
      transactionSelectedIndex: -1,
      sin_transacciones: require("../assets/imgs/home/sin_transac.svg"),
      entrando: false,
      header: 0,
      footer: 0,
      cont_visible: false,
      contenido_home: {},
    };
  },
  computed: {
    ...mapState([
      "remitente",
      "configuracion",
      "transaccion",
      "costos_pais",
      "heigthScreen",
      "widthScreen",
      "plataforma",
    ]),

    rutReact() {
      let aux = "";
      if (Object.keys(this.remitente).length > 0) {
        aux = this.remitente.numero_documento;
      }
      return aux;
    },
    nombre_simple_react() {
      let aux = "";
      if (Object.keys(this.remitente).length > 0) {
        aux = `${this.remitente.nombre1} ${this.remitente.nombre2} ${this.remitente.apellido1} ${this.remitente.apellido2}`;
      }
      return aux;
    },
    dolar_react() {
      let aux = "";
      if (this.configuracion && this.configuracion.tasasCambio) {
        let tasa_dolar_index = this.configuracion.tasasCambio.findIndex(
          (moneda_pais) => {
            return moneda_pais.id_moneda_destino == "2";
          }
        );
        if (tasa_dolar_index > -1) {
          aux = Math.floor(
            1 / this.configuracion.tasasCambio[tasa_dolar_index].valor_cambio
          );
        }
      }
      aux = `1USD = ${aux}CLP`;
      return aux;
    },
    fecha_larga() {
      let aux = "";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      aux = new Date().toLocaleDateString(
        `${this.locale}-${this.locale.toUpperCase()}`,
        options
      );
      return aux;
    },
    ultimasTransacciones() {
      let aux = [];
      if (Object.keys(this.remitente).length > 0) {
        let aux1 = Object.entries(this.remitente.destinatarios);
        let objTransaction = {};
        aux1.forEach((destin) => {
          if (
            destin[1].ultimas_transacciones &&
            destin[1].ultimas_transacciones.length > 0
          ) {
            destin[1].ultimas_transacciones.forEach((transac) => {
              objTransaction = {};
              // objTransaction = {};
              if (transac.banco_nombre) {
                objTransaction.banco = transac.banco_nombre;
              } else if (transac.nombre) {
                objTransaction.banco = transac.nombre;
              }

              objTransaction.id_persona = transac.destinatario_id;
              objTransaction.moneda_destino_id = transac.moneda_destino;
              objTransaction.moneda_destino_nombre =
                transac.moneda_destino_nombre;
              objTransaction.monto_origen = transac.monto_inicio;
              objTransaction.monto_pagar = transac.monto_inicio;

              objTransaction.monto_destino = transac.monto_destino;
              objTransaction.pais_destino = transac.pais_destino_id;
              objTransaction.mensaje_destinatario =
                transac.mensaje_destinatario;

              objTransaction.vigencia_pais =
                this.configuracion.pais[
                  `${transac.pais_destino_id}_${transac.moneda_destino}`
                ].pausa == "1" ||
                this.configuracion.pais[
                  `${transac.pais_destino_id}_${transac.moneda_destino}`
                ].activo == "0"
                  ? false
                  : true;

              if (
                transac.destinatario_id &&
                transac.destinatario_forma_entrega_id &&
                this.remitente.destinatarios[transac.destinatario_id]
                  .detalle_entrega
              ) {
                objTransaction.vigencia_destinatario =
                  !this.remitente.destinatarios[transac.destinatario_id] ||
                  this.remitente.destinatarios[transac.destinatario_id]
                    .activo == "0" ||
                  !this.remitente.destinatarios[transac.destinatario_id]
                    .detalle_entrega[transac.destinatario_forma_entrega_id]
                    ? false
                    : true;
              } else {
                objTransaction.vigencia_destinatario = false;
              }

              objTransaction.destinatario_nombres = `${transac.destinatario_nombre_1} ${transac.destinatario_apellido_1} ${transac.destinatario_apellido_2}`;
              objTransaction.fecha_ingreso = transac.fecha_inscripcion;
              objTransaction.hora_ingreso = transac.hora_ingreso;
              objTransaction.destinatario_apellido1 =
                transac.destinatario_apellido_1;
              objTransaction.destinatario_apellido2 =
                transac.destinatario_apellido_2;
              if (transac.banco_nombre) {
                objTransaction.destinatario_banco_destino_nombre =
                  transac.banco_nombre;
              } else if (transac.nombre) {
                objTransaction.destinatario_banco_destino_nombre =
                  transac.nombre;
              }

              objTransaction.pais_destino_nombre = transac.pais_destino_nombre;

              objTransaction.id_estado_pago = transac.id_estado_pago;
              objTransaction.id_estado_envio = transac.id_estado_envio;
              objTransaction.id_estado_retiro = transac.id_estado_retiro;

              objTransaction.bancos_id = transac.bancos_id;
              objTransaction.bancos_tipo_cuentas_id =
                transac.bancos_tipo_cuentas_id;
              objTransaction.nro_cuenta = transac.nro_cuenta;
              objTransaction.motivo_transaccion = transac.motivo_transaccion_id;

              if (transac.forma_entrega_id) {
                objTransaction.forma_entrega_id = transac.forma_entrega_id;
              }
              if (transac.destinatario_forma_entrega_id) {
                objTransaction.detalle_entrega_id =
                  transac.destinatario_forma_entrega_id;
              }
              objTransaction.tipos_cuenta_nombre = transac.tipos_cuenta_nombre;
              objTransaction.mensaje_destinatario =
                transac.mensaje_destinatario;
              objTransaction.transaccion_id = transac.transaccion_id;

              if (
                transac.id_estado_pago != "3" &&
                transac.id_estado_envio != "4" &&
                transac.id_estado_envio != "5" &&
                transac.id_estado_envio != "6" &&
                transac.id_estado_retiro != "3"
              ) {
                aux.push(objTransaction);
              }
              // aux.push(objTransaction);
            });
          }
        });

        aux.sort((a, b) => {
          let a_f = new Date(`${a.fecha_ingreso} ${a.hora_ingreso}`);
          let b_f = new Date(`${b.fecha_ingreso} ${b.hora_ingreso}`);
          if (a_f > b_f) {
            return -1;
          }
          if (a_f < b_f) {
            return 1;
          }
          return 0;
        });
      }

      return aux;
    },
    sin_destinatarios() {
      let aux = true;
      if (
        this.remitente.destinatarios &&
        this.remitente.destinatarios.length > 0) {
        if (this.remitente.destinatarios.length == 1) {
          if (!this.remitente.destinatarios["0"]) {
            aux = false;
          }
        } else {
          aux = false;
        }
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateBloquearNavegacion",
      "updateMenuSpDesde",
      "updateProcesoGeneral",
      "updateTransaccion",
      "set_nav_stack",
      "reset_transaccion",
      "setshowSpinner",
      "updateDesde",
    ]),
    ...mapActions([
      // "loginSimulado",
      "formarReciboTransferencia",
      "reload_tasas_costo_serv_pausa",
      "formatCotizadorResumen",
      "validarDestinoSelected",
      "validacionesAux",
      // "validarTarjetaBeneficiario",
      "repetirTransaccion",
      "calcular_monto_origen",
      "traer_transacciones",
    ]),

    async repetirTransac_bloqueado(motivo) {
      let texto = "";
      if (motivo == "pais") {
        texto = this.t("app.home.destino_bloqueado");
      } else if (motivo == "destinatario") {
        texto = this.t("app.home.destinatario_error");
      }
      let alert = await alertController.create({
        // header: this.title,
        subHeader: texto,
        buttons: ["Ok"],
        backdropDismiss: false,
      });
      await alert.present();
    },
    formatNumber2(numerito) {
      if (numerito) {
        return numerito.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      } else {
        return numerito;
      }
      // console.log(numerito);
    },

    callButtonMenuSp() {
      menuController.open("firstMenu");
    },

    callInfoLocal() {
      let obj = {
        desde: ["home"],
        menuSp: false,
      };
      this.set_nav_stack(obj);
      this.$router.push({
        name: "centroAyuda",
        params: { tema: "0" },
        replace: true,
      });
    },

    transactionSelectedLocal(data) {
      this.transactionSelectedIndex = data;
    },
    async mostrarDetalleTransacLocal(data) {
      this.updateBloquearNavegacion(true);
      this.formarReciboTransferencia(this.ultimasTransacciones[data]);
      const modal = await modalController.create({
        component: reciboTransferencia,
      });

      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    callEnviar() {
      // this.set_nav_stack({desde:["home"], menuSp:false});
      this.$router.push({ name: "cotizador", replace: true });
    },
    call_nuevo_destinatario() {
      this.set_nav_stack({ desde: ["home"], menuSp: false });
      this.$router.push({ name: "nuevoDestinatarioDisclaimer" });
    },
    callNewDestinatario() {
      this.set_nav_stack({ desde: ["home"], menuSp: false });
      // this.$router.push({ name: "nuevoDestinatarioDisclaimer" });
      this.$router.push({ name: "destinatarios", replace: true });
    },

    async repetirTransacLocal(index) {
      this.setshowSpinner(true);
      await this.reload_tasas_costo_serv_pausa();

      this.setshowSpinner(false);

      this.updateTransaccion({
        id_persona: this.ultimasTransacciones[index].id_persona,
        pais_destino: `${this.ultimasTransacciones[index].pais_destino}_${this.ultimasTransacciones[index].moneda_destino_id}`,
        detalle_entrega_id: this.ultimasTransacciones[index].detalle_entrega_id,
        monto_destino: this.ultimasTransacciones[index].monto_destino,
      });

      this.$router.push({
        name: "repetir_giro",
        replace: true,
      });
    },
  },
  watch: {
    heigthScreen() {
      this.contenido_home = contenido_style(
        this.heigthScreen,
        this.header,
        0,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  created() {},
  async mounted() {
    //Simular
    // await this.loginSimulado();
  },
  async ionViewWillEnter() {
    this.updateDesde("home");
    this.updateMenuSpDesde({ desde: "home", menuSp: false });
    this.updateProcesoGeneral("home");
    this.reset_transaccion();

    this.traer_transacciones();

    this.setshowSpinner(true);
    await this.reload_tasas_costo_serv_pausa();
    this.setshowSpinner(false);
  },
  ionViewDidEnter() {
    console.log(
      this.configuracion,
      this.remitente,
      this.transaccion.pais_destino
    );
    this.entrando = !this.entrando;

    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_home"].offsetHeight;
        this.footer = this.$refs["footer_home"].offsetHeight;
        this.cont_visible = true;
        // console.log(this.heigthScreen, this.header, this.footer - 16, this.plataforma == "web" ? 63 : 0);
        this.contenido_home = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
      }, 100);
    });
  },
  ionViewWillLeave() {
    this.cont_visible = false;
  },
};
</script>


<style >
.myHeader_home {
  background: #ffffff;
  z-index: 1000;
}
.home_contenido_sin_blur_sup {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
}
.home_body {
  height: 100%;
  width: 100%;
}
.contenido_home {
  /* overflow-y: auto !important; */
  z-index: 10;
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1.2rem;
  background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
  border-radius: 33px;
}

.homeMisTransCont {
  height: 100%;
  width: 100%;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.footer_home {
  padding-top: 0.5rem;
  height: 6rem;
  width: 100%;
}
.footer_home_btns {
  width: 90%;
}

.home_banner {
  height: 76px;
}
.home_banner_text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  color: #787878;
  padding-top: 1rem;
}
.home_banner_dolar_fecha {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.7rem;
}

.tarjetaBienvenida {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tarjetaBienvenidaTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1px;
  color: #ffffff;
}
.tarjetaBienvenidaTexto1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  color: #c4c4c4;
  margin-top: 0.3125rem;
}
.tarjetaBienvenidaDolar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  color: #787878;
}
.tarjetaBienvenidaFecha {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  color: #787878;
}

.homeMisTransTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1px;
  color: #801b1c;
  padding-bottom: 0.6rem;
  /* padding-left: 0; */
  left: 0;
  right: 0;
}

.homeTransList {
  height: 95%;
  max-height: 90%;
  width: 100%;
  overflow-y: auto;
  padding-top: 2rem;
}

.homeFooterTabs {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding-bottom: 0.2rem;
  z-index: 100;
}

.homeFooterTabsIcon {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin: auto;
  cursor: pointer;
}

.homeFooterTabsIcon img {
  width: 100%;
  height: 100%;

  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.homeFooterTabsSpan {
  text-align: center;
  line-height: 11px;
  cursor: pointer;
}
.homeFooterTabsSpan span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #787878;
}

.home_misTransacBody {
  margin-top: 0.6rem;
  height: 100%;
}
.home_sin_transac_Img {
  padding-top: 2.2rem;
  display: flex;
  justify-content: center;
}
.home_sin_transac_Tit {
  margin-top: 1rem;
  padding: 0 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  color: #614ad3;
}
.home_sin_transac_Text {
  margin-top: 0.875rem;
  padding: 0 3rem 2rem 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 1px;
  color: #787878;
}
.home_sin_transac_Text_2 {
  margin-top: 2rem;
  padding: 0 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  color: #614ad3;
  text-decoration: underline;
  cursor: pointer;
}
/* .home_sin_transac_Text_3 {
  margin-top: 4rem;
  padding: 0 3rem 2rem 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #787878;
  text-decoration: underline;
  cursor: pointer;
} */
</style>