<template>
  <div class="headerLogoCampCont">
    <div class="headerLogoCamp">
      <div>
        <div class="headerLogo">
          <img src="../../assets/imgs/logo.svg" alt="" srcset="" />
          <!-- <p>* {{nombre}} ({{ rut }}) *</p> -->
          <p>{{ nombre }}</p>
        </div>
      </div>
      <div class="headerCamp" @click.stop="toggleNotif = !toggleNotif">
        <!-- <div class="campCont">
          <img
            :src="campanaConNotif"
            alt=""
            srcset=""
            class=""
            v-if="notifications.length > 0"
          />
          <img :src="campanaSinNotif" alt="" srcset="" class="" v-else />
        </div> -->
      </div>
    </div>
    <!-- <div
      class="headerLogoCampMsgCont"
      v-show="notifications.length > 0 && toggleNotif"
      @click.stop=""
    >
      <div class="mensajesContDivGeneral">
        <div v-for="(noti, index) in notifications" :key="index">
          <cardNotificacionTransac
            v-if="noti.cat == 't'"
            :noti="noti"
            :id="index"
            @eliminar="eliminarNoti(index)"
          />

          <cardNotificacionContingencia
            v-if="noti.cat == 'c'"
            :noti="noti"
            :id="index"
            @eliminar="eliminarNoti(index)"
          />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// import cardNotificacionTransac from "./cardNotificacionTransac.vue";
// import cardNotificacionContingencia from "./cardNotificacionContingencia.vue";

export default {
  props: {
    rut: String,
    nombre: String,
  },
  data() {
    return {
      // campanaConNotif: require("../../assets/imgs/campanaConNotif.svg"),
      // campanaSinNotif: require("../../assets/imgs/campanaSinNotif.svg"),
      // toggleNotif: false,
    };
  },
  components: {
    // cardNotificacionTransac,
    // cardNotificacionContingencia,
  },
  computed: {
    ...mapState([
      // "notifications", 
      "focusGlobal"]),
  },
  methods: {
    ...mapMutations([
      // "updateFocus"
      ]),
    // eliminarNoti(i) {
    //   console.log(this.notifications[i]);
    // },
  },
  watch: {
    // focusGlobal() {
    //   console.log(this.focusGlobal);
    //   if (this.focusGlobal) this.toggleNotif = false;
    // },
    // toggleNotif() {
    //   if (this.toggleNotif) this.updateFocus(false);
    // },
  },
  mounted() {},
};
</script>

<style scoped>
.headerLogoCampCont {
  z-index: 10;
  position: relative;
  height: 100%;
}
.headerLogoCamp {
  display: flex;
  justify-content: space-between;
}
.headerLogo {
  width: 100%;
}
.headerLogo img {
  width: 45%;
}
.headerLogo p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--customF-C4C4C4);
  text-align: left;
  margin-bottom: 0;
  width: 100%;
  /* padding-left: 0.8rem; */
  padding-top: 0.3rem;
}
.headerCamp {
  height: 2rem;
  position: relative;
}
.campCont {
  z-index: 10;
}
.headerLogoCampMsgCont {
  position: absolute;
  top: 100%;
  right: 0;
  width: 12rem;
  padding: 0.8rem 0.85rem 0.4rem 0.85rem;
  overflow-y: auto;
  z-index: 5000;

  background: var(--customD-ECECEC);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
}
.mensajesContDivGeneral {
  width: 100%;
}
</style>>
